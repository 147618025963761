import { HIDE_LOADING_COMPONENT, LOGIN_INITIATE, LOGIN_SUCCESS, LOGOUT_INITIATE, SHOW_LOADING_COMPONENT, SHOW_SIDEBAR_COMPONENT, SIDEBAR_UNFOLDABLE } from "../actions/appActions";
import { saveState } from "../localStorage";


export const defaultAppState = {
    token:undefined,
    user:{
        "username":"",
        "email":"",
        "firstname":"",
        "display_name":"",
        "mobile":"",
        "profile_pic":""
    },
    roles:undefined,
    sidebarShow: true,
    sidebarUnfoldable:false
}

export const defaultLoadingState ={
    status:false
}

export const loaderReducer = (
    state = {
        ...defaultLoadingState
    },
    action
) => {
    switch ((action.type)) {
        case SHOW_LOADING_COMPONENT:
            return {
                ...state,
                status:true
            }
        case HIDE_LOADING_COMPONENT:
            return {
                ...state,
                status:false
            }
    
        default:
            return state
    }
}

export const appReducer = (state={...defaultAppState},action ) => {
    switch(action.type){
        case LOGIN_INITIATE:
            return {
                ...state,
                user:{
                    "username":"",
                    "email":"",
                    "firstname":"",
                    "display_name":"",
                    "mobile":"",
                    "profile_pic":""
                },
                token:undefined,
                roles:undefined
            }
        case LOGIN_SUCCESS:
            let newState = {
                ...state,
                user:{
                    ...action.payload.user
                },
                token:action.payload.token,
                roles:action.payload.roles
            }
            saveState(newState);// for persistent storage of the user details.
            return {
                ...newState
            }
        case LOGOUT_INITIATE:
            let newStateLogout = {
                ...defaultAppState,
                token:undefined,
                roles:undefined
            }
            saveState(newStateLogout);// for persistent storage of the user details.
            return {
                ...newStateLogout
            }
        case SHOW_SIDEBAR_COMPONENT:
            return {
                ...state,
                sidebarShow:action.payload.sidebarShow
            }
        case SIDEBAR_UNFOLDABLE:
            return {
                ...state,
                sidebarUnfoldable: action.payload.sidebarUnfoldable
            }
        default:
            return state;
    }
}

export const defaultDrugState = {
    data : [],
    drugSelected:undefined,
}

export const LOAD_DRUG_DATA = "LOAD_DRUG_DATA";
export const SELECT_DRUG_INFO = "SELECT_DRUG_INFO";
export const ADD_DRUG_INFO = "ADD_DRUG_INFO";

export const drugReducer = (state={...defaultDrugState},action) => {
    switch(action.type){
        case LOAD_DRUG_DATA:
            return {
                ...state,
                data: [...action.payload.data]
            }
        case SELECT_DRUG_INFO:
            return {
                ...state,
                drugSelected: {...action.payload.record}
            }
        case ADD_DRUG_INFO:
            return {
                ...state,
                data:[...state.data,action.payload.record],
                drugSelected: {...action.payload.record}
            }
        default:
            return state;
    }
}