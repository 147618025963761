import { toast } from "react-toastify";
import { ToastView } from "../components/Toast";


export const SHOW_TOAST_SUCCESS = "SHOW_TOAST_SUCCESS";
export const SHOW_TOAST_ERROR = "SHOW_TOAST_ERROR";
export const SHOW_TOAST_WARNING = "SHOW_TOAST_WARNING";
export const SHOW_TOAST_INFO = "SHOW_TOAST_INFO";
export const CLEAR_TOAST_MESSAGE = "CLEAR_TOAST_MESSAGE";


const TOAST_VIEW_OPTIONS = {
    position: "top-right",
    hideProgressBar:false,
    pauseOnHover:true
};

export const defaultToastState = {
    message:"",
    type:""
}

export const toastReducer = (
    state = {
       ...defaultToastState
    },
    action
) => {
    switch(action.type){
        case SHOW_TOAST_WARNING:
            toast(
                <ToastView  message={action.payload.message} type="warning" />,
                TOAST_VIEW_OPTIONS
            )
            return {
                ...state,
                message: action.payload.message,
                type:"warning"
            }
        case SHOW_TOAST_SUCCESS:
            toast(
                <ToastView  message={action.payload.message} type="success" />,
                TOAST_VIEW_OPTIONS
            )
            return {
                ...state,
                message: action.payload.message,
                type:"success"
            }
        case SHOW_TOAST_ERROR:
            toast(
                <ToastView  message={action.payload.message} type="error" />,
                TOAST_VIEW_OPTIONS
            )
            return {
                ...state,
                message: action.payload.message,
                type:"error"
            }
        case SHOW_TOAST_INFO:
            toast(
                <ToastView  message={action.payload.message} type="info" />,
                TOAST_VIEW_OPTIONS
            )
            return {
                ...state,
                message: action.payload.message,
                type:"info"
            }
        case CLEAR_TOAST_MESSAGE:
            return {
                ...state,
                message: "",
                type:""
            }
        default:
            return state
    }
}