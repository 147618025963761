import axios from 'axios';
import { env } from '../environment';
import { SHOW_TOAST_ERROR } from '../reducers/toastReducers';
import { hideLoader, showLoader } from '../util';

export const SHOW_LOADING_COMPONENT = "SHOW_LOADING_COMPONENT";
export const HIDE_LOADING_COMPONENT = "HIDE_LOADING_COMPONENT";

export const SHOW_SIDEBAR_COMPONENT = "SHOW_SIDEBAR_COMPONENT";

export const SIDEBAR_UNFOLDABLE = "SIDEBAR_UNFOLDABLE";

export const LOGIN_INITIATE = "LOGIN_INITIATE";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT_INITIATE = "LOGOUT_INITIATE";

export const authenticateUser = (username,password) => async (dispatch,getState) => {
    let callInfo = {
        "URL":env["API_BASE_URL"] + env["LOGIN_URL"],
        "DATA":{
            "username":username,
            "password":password
        }
    };
    showLoader(dispatch);
    callBackendAPI("POST",callInfo).then(response=>{
        let data = response.data;
        if(data["message"] !== undefined){
            throw  new Error(data["message"])
        }
        dispatch({
            type: LOGIN_SUCCESS,
            payload: data
        })
        hideLoader(dispatch);
    }).catch(error=>{
        dispatch({
            type: SHOW_TOAST_ERROR,
            payload: {
                message: error.message
            }
        })
        hideLoader(dispatch);
    })
}

export const callBackendAPI = (method,callsInfo) => {

    let config = {};
    let url = callsInfo["URL"];

    if(callsInfo["AUTH"] === true){
        config = {
            headers: {
                'Authorization': 'Bearer ' + callsInfo["TOKEN"]
              },
        };

        if(callsInfo["FILE_UPLOAD"] !== undefined){
            config["headers"]["Content-Type"] = "multipart/form-data";
        }
    }


    if(method === "GET"){
        return axios.get(url,config)

    }else if(method === "POST"){
        let data = callsInfo["DATA"];
        return axios.post(url,data,config);
    }else if(method === "DELETE"){
        return axios.delete(url,config)
    }

 }

 export const downloadFile = (method,callsInfo) => {

    let config = {};
    let url = callsInfo["URL"];

    if(callsInfo["AUTH"] === true){
        config = {
            headers: {
                'Authorization': 'Bearer ' + callsInfo["TOKEN"]
              },
            responseType: 'blob'
        };
    }


    if(method === "POST"){
        let data = callsInfo["DATA"];
        return axios.post(url,data,config);
    }

 }