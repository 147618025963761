import { CSpinner } from "@coreui/react";
import "./Loader.css"


const Loader = (props) => {
    return props.loading === true ?  (
        <div className="loader">
            <CSpinner  />
        </div>
    ):
    ""
}



export default Loader;