import React, { Suspense } from 'react';
import {  BrowserRouter as Router,Route, Routes } from 'react-router-dom';

import './scss/style.scss'
import './App.css';
import "react-widgets/styles.css";
import Loader from './components/Loader/Loader';
import { connect } from 'react-redux';
import { ToastComponent } from './components/Toast';
import { authenticateUser } from './actions/appActions';



const Login = React.lazy(() => import('./pages/guest/Login'))
const ForgotPassword = React.lazy(() => import('./pages/guest/ForgotPassword'))
const ResetPassword = React.lazy(() => import('./pages/guest/ResetPassword'))
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

const mapStateToProps = (state,ownProps) => {
  return {
    loading:state.loading.status,
    toast : state.toast,
    token : state.app.token,
    user: state.app.user
  }
}
const mapDispatchToProps = (dispatch,ownProps) => {
  return {
    authenticateUser: (username,password) => dispatch(authenticateUser(username,password)),
  }
}

function App({loading,toast,token,user,authenticateUser}) {
  return (
    <Suspense fallback={<Loader loading={true} />}>
      <Router>
        <Routes>
          <Route exact path="/login" name="Login Page" element={<Login token={token} authenticateUser={authenticateUser} />} />
          <Route exact path="/forgot-password" name="Forgot Password Page" element={<ForgotPassword />} />
          <Route exact path="/reset-password" name="Reset Password Page" element={<ResetPassword />} />
          <Route exact path="/" name="Login Page" element={<Login token={token} authenticateUser={authenticateUser} />} />
          <Route path="*" name="Home" element={<DefaultLayout token={token}  user={user} />} />
        </Routes>
      </Router>
      <ToastComponent {...toast}  />
      <Loader loading={loading} />
    </Suspense>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
