import { HIDE_LOADING_COMPONENT, SHOW_LOADING_COMPONENT } from "./actions/appActions";

export const sanitizeData = (value) => {
    if(value.length > 9999999)
        return
    value = value.replace(/<script>/g, "");
    value = value.replace(/<\/script>/g, "");
    return value;
}

export const hideLoader = (dispatch) =>{
    dispatch({
        type: HIDE_LOADING_COMPONENT
    })
}

export const showLoader = (dispatch) =>{
    dispatch({
        type: SHOW_LOADING_COMPONENT
    })
}

export const generateGetRecordsURL = (url,limit,startRecord,sort_by=[],filter_by=[]) => {
    let sort_fields_str = "";
    let filter_by_str = "";
    // for (let index = 0; index < sort_by.length; index++) {
    //     const elementSort = sort_by[index];
    //     sort_fields_str += "&sort_by="+encodeURI(elementSort)
    // }
    if(sort_by.length == 2){
        sort_fields_str = "&order_by_field="+encodeURI(sort_by[0]) + "&order_by="+encodeURI(sort_by[1])
    }
    for (let index = 0; index < filter_by.length; index++) {
        const elementFilter = filter_by[index];
        filter_by_str += "&filter_by="+encodeURI(elementFilter)
    }
    return url + '?limit='+limit+'&start='+startRecord + sort_fields_str+filter_by_str;
}

export const generateSearchURL = (url,table,searchText,limit,startRecord,search_fields=[],sort_by=[],filter_by=[]) => {
    let sort_fields_str = "";
    let search_fields_str = "";
    let filter_by_str = "";

    for (let index = 0; index < sort_by.length; index++) {
        const elementSort = sort_by[index];
        sort_fields_str += "&sort_by="+encodeURI(elementSort)
    }

    for (let index = 0; index < search_fields.length; index++) {
        const elementSearch = search_fields[index];
        search_fields_str += "&search_fields="+encodeURI(elementSearch)
    }

    for (let index = 0; index < filter_by.length; index++) {
        const elementFilter = filter_by[index];
        filter_by_str += "&filter_by="+encodeURI(elementFilter)
    }

    return  url + "?table="+encodeURI(table) +'&limit='+limit+'&start='+startRecord + 
        "&value="+encodeURI(searchText)+sort_fields_str+search_fields_str+filter_by_str;
}

export const convertDateToMySQLFormat =  (date_) => {
    let hours = date_.getHours();
    if(hours < 10){
        hours = "0" + hours;
    }

    let month = date_.getMonth() + 1;
    if(month < 10){
        month = "0" + month;
    }

    let date__ = date_.getDate();
    if(date__ < 10){
        date__ = "0" + date__;
    }

    let year = date_.getFullYear();
    if(year < 10){
        year = "0" + year;
    }

    let minutes = date_.getMinutes();
    if(minutes < 10){
        minutes = "0" + minutes;
    }

    let seconds = date_.getSeconds();
    if(seconds < 10){
        seconds = "0" + seconds;
    }

    return year + "-" + month + "-" + date__ + " " +
        hours + ":" + minutes + ":" + seconds;
}

export const convertDateToMySQLFormatDate =  (date_) => {
    
    let month = date_.getMonth() + 1;
    if(month < 10){
        month = "0" + month;
    }

    let date__ = date_.getDate();
    if(date__ < 10){
        date__ = "0" + date__;
    }

    let year = date_.getFullYear();
    if(year < 10){
        year = "0" + year;
    }


    return year + "-" + month + "-" + date__ ;
}

export const isEmpty = (val) => {
    if(val !== undefined && val !== null &&  val !== "")
            return false;
    return true;
}

export const convertMySQLDateToJSDate = (date_) => {
    var dateParts = date_.split("-");
    return new Date(dateParts[0], dateParts[1] - 1, dateParts[2].substr(0,2));
}

export const convertMySQLDateToStrIndianDate =  (date_) => {
    var t = date_.split(/[- :]/);
    return t[2] +"-" +t[1]+"-" + t[0];
}

export const converMySQLDateFormatToDate =  (date_) => {
    var t = date_.split(/[- :]/);
    
    return new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);
}

export const converMySQLDateFormatToDateStr =  (date_) => {
    var t = date_.split(/[- :]/);
    
    return t[2] +"-" +t[1]+"-" + t[0]+" " + t[3]+":" +t[4]+":" +t[5];
}


export function getIndianFormatDate(date,separator="/") {
    var year = date.getFullYear();
  
    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;
  
    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;
    
    return day + separator + month + separator + year;
  }