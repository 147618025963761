export const hostname = window && window.location && window.location.hostname;

export const env = {
    "COMPANY_NAME":"Raghu's Homeo",
    "COMPANY_URL":"https://app.drraghuram.in",
    "APP_DESCRIPTION":"Hospital Management System Portal",
    "API_BASE_URL":"https://hmsapi.drraghuram.in",
    "LOGIN_URL":"/site/auth",
    "FORGOT_PASSWORD_URL":"/site/forgot",
    "RESET_PASSWORD_URL":"/site/reset-password",
    "VALIDATE_RESET_PASSCODE_URL":"/site/validate-reset-pass-code",
    "DASHBOARD_URL":"/user/dashboard",
    "CHANGE_PASSWORD_URL":"/user/change-password",
    "ROLES_URL":"/admin/roles",
    "REMOVE_ROLE_URL":"/admin/remove-role",
    "ADD_USER_URL":"/admin/add-user",
    "CLIENT_URL":"/admin/client",
    "ASSIGN_CLIENT_URL":"/admin/assign-client",
    "DOCTOR_GET_PATIENTS":"/doctor/patients",
    "DOCTOR_ADD_PATIENT_ADDRESS":"/doctor/patient-address",
    "DOCTOR_UPDATE_PATIENT_ADDRESS":"/doctor/patient-update-address",
    "DOCTOR_DELETE_PATIENT":"/doctor/delete-patient",
    "DOCTOR_GET_PATIENT":"/doctor/patient",
    "DOCTOR_GET_PATIENT_NOTES":"/doctor/notes",
    "DOCTOR_GET_PATIENT_VISITS":"/doctor/visits",
    "DOCTOR_SAVE_PATIENT_NOTES":"/doctor/save-notes",
    "DOCTOR_GET_PATIENT_APPOINTMENTS":"/doctor/appointments",
    "DOCTOR_PATIENT_APPOINTMENT":"/doctor/appointment",
    "DOCTOR_GET_CASE_TYPES":"/doctor/case-types",
    "DOCTOR_GET_CHARGE_TYPES":"/doctor/payment-type-account",
    "DOCTOR_GET_FILE_TYPES":"/doctor/file-types",
    "DOCTOR_GET_FILES":"/doctor/files",
    "DOCTOR_CASE_DETAILS":"/doctor/case-details",
    "DOCTOR_GET_CASE_DETAIL":"/doctor/case-detail",
    "DOCTOR_CASE_CHARGE":"/doctor/charge",
    "DOCTOR_SAVE_CASE_DETAIL":"/doctor/save-case-detail",
    "DOCTOR_VISIT_CASE_DETAIL":"/doctor/visit-case-detail",
    "DOCTOR_CASE_DETAIL_VISIT":"/doctor/case-detail-visit",
    "DOCTOR_CASE_DETAIL_VISIT_UPDATE":"/doctor/update-case-detail-visit",
    "DOCTOR_DELETE_CASE_DETAIL_VISIT":"/doctor/delete-case-detail-visit",
    "DOCTOR_DELETE_FILE":"/doctor/delete-file",
    "DOCTOR_COPY_CASE_DETAIL":"/doctor/save-visit-case-detail",
    "DOCTOR_CLONE_CASE_DETAIL":"/doctor/clone-case-detail",
    "DOCTOR_DOWNLOAD_FILE":"/doctor/download-file",
    "GET_DRUG_INFO":"/doctor/drug",
    "GET_TAG_INFO":"/doctor/tag",
    "GET_PRESCRIPTION":"/doctor/prescription",
    "EDIT_PRESCRIPTION":"/doctor/edit-prescription",
    "DELETE_PRESCRIPTION":"/doctor/delete-prescription",
    "ADMIN_GET_LOGS":"/admin/logs",
    "ADMIN_SEARCH_LOGS":"/admin/log-search",
    "ADD_VISIT_CONFIRMATION":false,
    "CREATE_DEFAULT_CASES":["General","Investigations"],
    "CREATE_PAYMENT_DEFAULT_FOR_CASE":true,
    "CREATE_PAYMENT_DEFAULT_PAYMENT_ID":1,
    "DOSAGE_FORM":["Tab.","Cap.","Inj.","Syr.","Drops","Ointment"],
    "DRUG_DURATION_UNIT":["","hours","days","weeks","years"],
    "PRESCRIPTION_DIRECTION":["","Before meals","After meals"],
    "DRUG_FREQUENCY":["If required","Immediately","Once a day","Twice daily","Thrice daily",
    "Four times a day","Every hour","Every night at bedtime","Every day","Every other day","Every four hours",
    "Once a week","Three times a week"
    ],
    "PRESCRIPTION_MODE":["","Pills","Dilution"]
    
}