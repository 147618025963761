import { legacy_createStore, combineReducers, applyMiddleware } from 'redux';

import thunk from 'redux-thunk';

import { appReducer, loaderReducer } from './reducers/appReducer';
import { defaultToastState, toastReducer } from './reducers/toastReducers';
import { loadState } from './localStorage';
import { defaultLoadingState } from './reducers/appReducer';

const reducer = combineReducers({
    app:appReducer,
    toast:toastReducer,
    loading:loaderReducer
});

const initialStore = loadState();


const middleware = [thunk];

const store = legacy_createStore(
    reducer,
    {app:{...initialStore},loading:{...defaultLoadingState},toast:{...defaultToastState}},
    applyMiddleware(...middleware)
)

export default store;