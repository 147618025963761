import { defaultAppState } from "./reducers/appReducer";

export const loadState = () => {
    try {
        const serializedState = localStorage.getItem('appState');
        if (serializedState === null) {
            return {...defaultAppState};
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return {...defaultAppState};
    }
};

export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('appState', serializedState);
    } catch (err) {
        // Ignore write errors.
    }
};