import { ToastContainer } from 'react-toastify';
import styled from "styled-components";
import { ErrorIcon, InfoIcon, SuccessIcon, WarningIcon } from './Icons';

export const StyledToastContainer = styled.div`
display:flex;
flex-direction: row;
& div:nth-child(2){
    flex-grow:1;
}
`;

export const ToastContent = styled.div`
    display:flex;
    flex-direction: column;
    margin-left:12px;
    flex-grow:1;
    color:${props=> props.color ? props.color:"#54A03D"};
    & div:first-child{
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }
    & div:last-child{
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
    }
`;

export const ToastItem = styled.div``

export const ToastTitle = styled.div``

export const ToastMessage = styled.div``

function capitalize(word) {
    const lower = word.toLowerCase();
    return word.charAt(0).toUpperCase() + lower.slice(1);
}

export const ToastView = (props) => {

    let title = "";
    if(props.title){
        title = props.title;
    }else{
        title = capitalize(props.type)
    }

   let color= '#54A03D';
   let icon = <SuccessIcon color={color} />;
   
    switch (props.type) {
        case 'warning':
            color= '#96722E';
            icon = <WarningIcon color={color} />
            break;
        case 'error':
            color= '#AB332F';
            icon = <ErrorIcon color={color} />
            break;
        case 'info':
            color= '#4E82AD';
            icon = <InfoIcon color={color} />
            break;
    
        default:
            break;
    }


    return (
        <StyledToastContainer>
                <ToastItem>
                    {icon}
                </ToastItem>

                <ToastItem>
                    <ToastContent color={color}>
                        <ToastTitle>
                            {title}
                        </ToastTitle>
                        <ToastMessage>
                            {props.message}
                        </ToastMessage>
                    </ToastContent>
                </ToastItem>
        </StyledToastContainer>
    )

}

export const ToastComponent = (props) => {

    let border_color = '#54A03D';
    let background_color= '#E3FCDC';
    switch (props.type) {
        case 'warning':
            border_color = '#96722E';
            background_color= '#FFF4DE';
            break;
        case 'error':
            border_color = '#AB332F';
            background_color= '#FFF2E5';
            break;
        case 'info':
            border_color = '#4E82AD';
            background_color= '#E1F0FF';
            break;
    
        default:
            break;
    }
    return (
        <ToastContainer style={{ width: "400px" }} toastStyle={{ backgroundColor: background_color, borderColor: border_color }} />
    )
}